{
  "name": "lifecycle-remastered",
  "version": "0.0.19",
  "description": "New version of lifecycle and iot-dashboard.",
  "repository": "https://jiiipeee@bitbucket.org/enveratech/lifecycle-remastered.git",
  "author": "JP Makela <juha-pekka.makela@envera.fi>",
  "license": "MIT",
  "private": true,
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "copyimages": "cp -vR ./src/img ./dist && cp ./src/img/favicon.ico ./dist",
    "prebuild": "rimraf dist && yarn copyimages && yarn copyprodconf",
    "prebuild:dev": "rimraf dist && yarn copyimages && yarn copydevconf",
    "build": "parcel build src/index.html && yarn buildTerminal",
    "build:dev": "parcel build src/index.html && yarn buildTerminal",
    "build:local": "parcel build src/index.html && yarn buildTerminal",
    "start": "parcel src/index.html",
    "clean": "rimraf dist",
    "copyprodconf": "cp ./src/auth/config_prod.js ./src/auth/config.js",
    "copydevconf": "cp ./src/auth/config_dev.js ./src/auth/config.js",
    "buildTerminal": "parcel build src/terminal/terminal.html",
    "sync:dev": "gsutil -m cp -r dist/* gs://devicedashboard.testflowone.io",
    "sync:prod": "gsutil -m cp -r dist/* gs://devicedashboard.flowone.io"
  },
  "dependencies": {
    "@azure/msal-browser": "^2.24.0",
    "@azure/msal-react": "^1.4.0",
    "@date-io/luxon": "^2.14.0",
    "@emotion/react": "^11.9.0",
    "@emotion/styled": "^11.8.1",
    "@envera/connect-communication": "^1.0.2",
    "@mui/icons-material": "^5.6.2",
    "@mui/material": "5.11.6",
    "@mui/x-data-grid": "^5.11.0",
    "@mui/x-date-pickers": "^5.0.0-alpha.6",
    "axios": "^1.2.3",
    "deep-object-diff": "^1.1.9",
    "luxon": "^3.2.1",
    "react": "^18.1.0",
    "react-dom": "^18.1.0",
    "react-router-dom": "^6.3.0",
    "semver": "^7.3.7"
  },
  "devDependencies": {
    "buffer": "^5.5.0",
    "events": "^3.3.0",
    "parcel": "^2.8.3",
    "process": "^0.11.10",
    "rimraf": "^4.1.2",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0",
    "util": "^0.12.4"
  }
}
