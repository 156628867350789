import {
    BlurLinear,
    ElectricMeter,
    Terminal,
    SystemUpdate,
    Autorenew,
    Article,
    Apartment,
    NotificationImportant,
    EventNote,
    CompareArrows,
    BarChart
} from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {
    Button,
    Grid,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Alert
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useAuth from '../auth/useAuth';
import config from '../auth/config';

export default function DeviceDetailsMenu(props) {

    const [deviceMenuOpen, setDeviceMenuOpen] = useState(false);
    const [dataMenuOpen, setDataMenuOpen] = useState(false);
    const [eventsMenuOpen, setEventsMenuOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { deviceId, meta, saveMetaData, selectionChanged } = props;
    const [metaData, setMetaData] = useState(JSON.stringify(meta));
    const [metaError, setMetaError] = useState(null);
    const [premiseId, setPremiseId] = useState(null);


    const { token, iotToken } = useAuth();


    useEffect(()=>{
        setMetaData(JSON.stringify(meta));
    },[meta]);

    useEffect(() => {
        async function findPremise() {
            try {
                let url = config.flowoneApiUrl + '/premises/find';
                let query = {
                    query: { devices: { $in: [deviceId] } }
                }
                let resp = await axios.post(url, query, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });
                setPremiseId(resp?.data[0]?._id);
            } catch (err) {
                console.error("Error quering premise:", err);
            }
        }
        findPremise();
    }, [deviceId]);

    const handleDeviceMenuClose = () => {
        console.log("Closing device menu");
        setDeviceMenuOpen(false);
        setAnchorEl(null);
    }
    const handleDeviceMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDeviceMenuOpen(true);
    }
    const handleDataMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDataMenuOpen(true);
    }
    const handleDataMenuClose = () => {
        setDataMenuOpen(false);
        setAnchorEl(null);
    }
    const handleEventsMenuOpen = (event) => {
        setEventsMenuOpen(true);
        setAnchorEl(event.currentTarget);
    }
    const handleEventsMenuClose = () => {
        setEventsMenuOpen(false);
        setAnchorEl(null);
    }
    const handleDialogClose = () => {
        setDialogOpen(false);
    }
    const handleDialogOpen = () => {
        setDialogOpen(true);
        try {
            let objData = JSON.parse(metaData);
            setMetaData(JSON.stringify(objData, undefined, 4));
            //TODO!! save metaData here!
            setMetaError(null);
        } catch (error) {
            setMetaError('Invalid JSON!');
        }
        handleDeviceMenuClose();
    }

    const handleOpenLifecycleDialog = () => {
        handleDeviceMenuClose();
        selectionChanged('openLifecycleDialog');
    }

    const handleDeviceConfigSelected = () =>  {
        selectionChanged('openDeviceConfigDetails');
        handleDeviceMenuClose();
    }

    const handleDeviceFileTransferSelected = () => {
        selectionChanged('openFileTransferDialog');
        handleDeviceMenuClose();
    }

    const handleMetaDataChange = (event) => {
        setMetaData(event.target.value);
    }

    const handleMetaDataSave = () => {
        try {
            let objData = JSON.parse(metaData);
            saveMetaData(metaData);
            handleDialogClose();
        } catch (error) {
            setMetaError('Invalid JSON!');
        }
    }

    const handleConfigMenu = () => {
        //window.open(config.setupAssistantUrl + '/#/' + deviceId, '_blank', 'noreferrer');
        openInTab(config.setupAssistantUrl + '/#/' + deviceId);
        handleDeviceMenuClose();
    }

    const handlePremisesLink = () => {
        //window.open(config.flowoneIoUrl + '/#/premises/edit/' + premiseId, '_blank', 'noreferer');
        openInTab(config.flowoneIoUrl + '/#/premises/edit/' + premiseId);
        handleDataMenuClose();
    }

    const handleReportLink = () => {
        //window.open(config.flowoneIoUrl + '/#/report/' + premiseId, '_blank', 'noreferer');
        openInTab(config.flowoneIoUrl + '/#/report/' + premiseId);
        handleDataMenuClose();
    }

    const handleAnalyzeLink = () => {
        //window.open(config.flowoneIoUrl + '/#/analyze/' + deviceId, '_blank', 'noreferer').focus();
        openInTab(config.flowoneIoUrl + '/#/analyze/' + deviceId);
        handleDataMenuClose();
    }

    const handleOpenTerminal = () => {
        console.log("Opening terminal for device.");
        //document.domain="testflowone.io";
        let terminalWindow = window.open('/terminal.html', "_blank", "width=805, height=460");
        var doLoad = function() {
            terminalWindow.postMessage({
              token: iotToken,
              id: deviceId,
              name: "Terminal",
              url: config.iotApiUrl
            }, '*');
          };
          if (terminalWindow.addEventListener) {
            terminalWindow.addEventListener("load", doLoad, false);
          } else if (terminalWindow.attachEvent) {
            terminalWindow.attachEvent("onload", doLoad);
          } else if (terminalWindow.onLoad) {
            terminalWindow.onload = doLoad;
          }
          handleDeviceMenuClose();
    }

    const handleAlarmsSelected = () => {
        handleEventsMenuClose();
        selectionChanged('openAlarmsDialog');
    }
    const handleEventsSelected = () => {
        handleEventsMenuClose();
        selectionChanged('openEventsDialog');
    }

    const handleUpdateDeviceSelected = () => {
        selectionChanged('openUpdateDialog');
        handleDeviceMenuClose();
    }

    const handleConnectionLogSelected = () => {
        selectionChanged('openConnectionLogDialog');
        handleEventsMenuClose();
    }

    const openInTab = (href)=>{
        Object.assign(document.createElement('a'),{
            target:'_black',
            rel:'noreferer',
            href
        }).click();
    }

    return (
        <Grid item container spacing={2} alignItems="flex-start" direction="column"  >
            <Grid item container >
                <Button
                    id="device-button"
                    aria-controls={deviceMenuOpen ? 'device-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={deviceMenuOpen ? 'true' : undefined}
                    onClick={handleDeviceMenuOpen}
                    endIcon={<KeyboardArrowDownIcon style={{ position: "absolute", bottom: 8, right: 5 }} />}
                    fullWidth
                    variant='outlined'
                >Device</Button>
                <Menu
                    id="device-menu"
                    anchorEl={anchorEl}
                    open={deviceMenuOpen}
                    onClose={handleDeviceMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleDialogOpen}>
                        <ListItemIcon>
                            <BlurLinear fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Metadata
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleConfigMenu}>
                        <ListItemIcon>
                            <ElectricMeter fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Setup Assistant
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenTerminal}>
                        <ListItemIcon>
                            <Terminal fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Terminal
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenLifecycleDialog}>
                        <ListItemIcon>
                            <Autorenew fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Lifecycle State
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleUpdateDeviceSelected}>
                        <ListItemIcon>
                            <SystemUpdate fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Update SW
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDeviceConfigSelected}>
                        <ListItemIcon>
                            <DisplaySettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Device Settings
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDeviceFileTransferSelected}>
                        <ListItemIcon>
                            <CloudSyncIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            File Transfer
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Grid>
            <Grid item container>
                <Button
                    id="data-button"
                    aria-controls={deviceMenuOpen ? 'data-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={deviceMenuOpen ? 'true' : undefined}
                    onClick={handleDataMenuOpen}
                    endIcon={<KeyboardArrowDownIcon style={{ position: "absolute", bottom: 8, right: 5 }} />}
                    fullWidth
                    variant='outlined'
                >Data</Button>
                <Menu
                    id="data-menu"
                    anchorEl={anchorEl}
                    open={dataMenuOpen}
                    onClose={handleDataMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleReportLink}>
                        <ListItemIcon>
                            <Article fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Open Report
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handlePremisesLink}>
                        <ListItemIcon>
                            <Apartment fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Open Premise
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleAnalyzeLink}>
                        <ListItemIcon>
                            <BarChart fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Open Analyze
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Grid>
            <Grid container item >
                <Button
                    id="events-button"
                    aria-controls={deviceMenuOpen ? 'events-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={deviceMenuOpen ? 'true' : undefined}
                    onClick={handleEventsMenuOpen}
                    endIcon={<KeyboardArrowDownIcon style={{ position: "absolute", bottom: 8, right: 5 }} />}
                    fullWidth
                    variant='outlined'
                >Events</Button>
                <Menu
                    id="events-menu"
                    anchorEl={anchorEl}
                    open={eventsMenuOpen}
                    onClose={handleEventsMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleAlarmsSelected}>
                        <ListItemIcon>
                            <NotificationImportant fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Alarms Log
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleConnectionLogSelected}>
                        <ListItemIcon>
                            <CompareArrows fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Connection Log
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleEventsSelected}>
                        <ListItemIcon>
                            <EventNote fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Events Log
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Grid>
            <Dialog
                open={dialogOpen}
                keepMounted
                fullWidth='md'
                onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{deviceId} Metadata</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <TextField fullWidth multiline value={metaData} onChange={handleMetaDataChange} />
                    </DialogContentText>
                </DialogContent>
                {metaError && <Alert sx={{ marginLeft: 2, marginRight: 2 }} severity='error' fullWidth >{metaError}</Alert>}
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                    <Button onClick={handleMetaDataSave}>Save</Button>
                </DialogActions>

            </Dialog>
        </Grid>
    )
}