//const io = require('socket.io-client');
//var ss = require('socket.io-stream');
const socketApi = require('../modules/socketApi');
const socketApiLegacy = require('../modules/socketApiLegacy');

const EventEmitter = require('events').EventEmitter;
const debug = require('debug')('com');

const socketServers = {};

const Api = new EventEmitter();
//Api.socketStream = ss;

const sockets = {};

var ss=null;

const errorCodes = {
  '-32601': 'Method not found',
  '-32602': 'Invalid params',
  '-32100': 'Timeout in request',
  '-32101': 'Device runtime error',
};

let transactionID = 1;
const transactionIdMap = {}; // key is tr id, content is callback function
const defaultTimeout = 7000; // timeout request after 7 seconds

function getNewTransactionId(callback, method, timeout) {
  const id = transactionID++;
  const timer = setTimeout(() => {
    delete transactionIdMap[id];
    if (callback) {
      callback(
        {
          code: -32100,
          error: new Error(errorCodes[-32100]),
        },
        undefined
      );
    }
  }, timeout || defaultTimeout);
  transactionIdMap[id] = {
    callback,
    timer,
    ts: new Date().getTime(),
    method,
  };
  return id;
}

function getCallbackForTransactionId(id) {
  const obj = transactionIdMap[id];
  if (obj && obj.timer) {
    clearTimeout(obj.timer);
  }
  if (obj && obj.callback) {
    delete transactionIdMap[id];
    return obj.callback;
  }
  return () => { };
}

function genericMsgHandler(obj, socket, stream) {
  if (obj.id === undefined) {
    // notification
    switch (obj.method) {
      case 'device-connected': {
        sockets[obj.from].deviceConnection = true;
        Api.emit('device-connected', obj.from);
        break;
      }
      case 'device-disconnected': {
        sockets[obj.from].deviceConnection = false;
        Api.emit('device-disconnected', obj.from);
        break;
      }
      case 'trace': {
        Api.emit('trace', obj.from, obj.params);
        break;
      }
      default: {
        Api.emit(obj.method, obj.from, obj.params, stream);
        Api.emit('variable', obj.from, {
          name: obj.method,
          value: obj.params && obj.params.value,
        });
        break;
      }
    }
  } else if (obj.result !== undefined || obj.error !== undefined) {
    // it is a response to request
    const o = obj;
    if (o.error !== undefined) {
      o.error = {
        message: obj.error,
        code: obj.code,
      };
    }
    getCallbackForTransactionId(o.id)(o.error, o.result, stream);
  }
}

Api.connect = opts => {
  const { token, id, server, oauth } = opts;
  debug('connect', id);
  let socket;
  var qs = `?token=${token}`;
  if (oauth) qs += '&oauth=1';
  if (sockets[id] && sockets[id].server === server) {
    debug('socket exits reconnecting', id);
    sockets[id].connect();
    socket = sockets[id];
    if (sockets[id].deviceConnection) {
      Api.emit('device-connected', id);
    }
  } else {
    let io = null;
    //legacy api servers
    if (server == "https://iot-socket1.flowone.io" ||
      server == "https://iot-socket2.flowone.io" ||
      server == "https://iot-socket3.flowone.io" ||
      server == "https://iot-socket1.testflowone.io") {
      io = socketApiLegacy;
      console.log("Using legacy socket.io client!");
    } else {
      io = socketApi;
      console.log("Using new socket.io client");
    }
    if (opts.serviceLogin || !socketServers[server]) {
      socketServers[server] = io.getManager(`${server}${qs}`, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 120000,
        timeout: 20000,
        transports: ['websocket'],
      });
    }

    socket = socketServers[server].socket(`/${id}`);

    sockets[id] = socket;
    sockets[id].server = server;

    socket.connect();
    ss = io.getSS();
    socket.streamSocket = ss(socket);
    socket.streamSocket.on('stream', function (stream, data) {
      //console.log('lcp-client-communication:stream received', data);
      genericMsgHandler(data, socket, stream);
    });
    // handle stream error
    socket.streamSocket.on('error', function () {
      // console.log('stream error', error);
    });

    socket.on('message', obj => {
      debug('message', obj);
      genericMsgHandler(obj, socket);
    });

    socket.on('disconnect', () => {
      debug('disconnect', id);
      Api.emit('device-disconnected', id);
    });

    socket.on('error', err => {
      debug('error', id, err);
      Api.emit('error', id, new Error(err));
      /*
      setTimeout(() => {
        socket.disconnect();
        Api.connect(token, id, server);
      }, 10000);
      */
    });
  }
  return socket;
};

Api.disconnect = id => {
  if (sockets[id]) {
    delete sockets[id].subscriptions;
    if (sockets[id]) {
      sockets[id].disconnect();
      delete sockets[id];
    }
  }
};

Api.send = function (id, method, params, timeout) {
  return new Promise((resolve, reject) => {
    if (!id) throw new Error('deviceId not set');
    if (!sockets[id]) throw new Error('device not connected');
    if (typeof method !== 'string') throw new Error('method must be a string');

    const tid = getNewTransactionId(
      (err, response, remoteStream) => {
        if (err) {
          return reject(err);
        }
        if (remoteStream) {
          return resolve({ response, remoteStream });
        }

        resolve(response);
      },
      method,
      timeout
    );

    const msg = {
      to: id,
      id: tid,
      method,
      params,
    };

    sockets[id].emit('message', msg);
  });
};

Api.sendStreamTo = function (id, method, params) {
  return Api.send(id, method, params)
    .then(obj => {
      var stream = ss.createStream();
      params.method = method;
      params.to = id;
      sockets[id].streamSocket.emit('stream', stream, params);
      return { stream, remoteStream: obj.remoteStream };
    })
    .catch(err => console.log(err));
};

Api.createStreamTo = function (id, method, params) {
  return new Promise(resolve => {
    var stream = ss.createStream();
    params.method = method;
    params.to = id;
    sockets[id].streamSocket.emit('stream', stream, params);
    resolve({stream,ss});
  });
};

Api.sendCustom = function (id, method, msg) {
  return new Promise(resolve => {
    sockets[id].emit(method, msg);
    resolve();
  });
};

module.exports = Api;
