import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import useAuth from "../auth/useAuth";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import config from "../auth/config.js";
import { getPremises } from "./Ownpremises";

//const axios = require("axios");
import axios from 'axios';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchAppBar({ getPremises }) {
  const [input, setInput] = React.useState("");
  const { user, token } = useAuth();

  axios.defaults.baseURL = config.flowoneApiUrl;

  React.useEffect(() => {
    console.log("use effect...")
    let result=sessionStorage.getItem('lastSearch');
    if(result){
      console.log("search results from storage")
      result = JSON.parse(result);
      passResult(result);
    }
  }, []);

  const getResult = () => {
    axios.get("/lifecycledata/premiseinfo/" + input, {
        headers: { authorization: `bearer ${token}` },
      })
      .then(function (response) {
        let outcome = response;
        console.log("saving result");
        sessionStorage.setItem('lastSearch', JSON.stringify(outcome));
        passResult(outcome);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  function click() {
    getResult();
    refreshId();
  }
  const getOwnResult = () => {
    let items = localStorage.getItem("ownPremises");
    if (items === null) {
      console.log("no items on storage");
    } else {
      // console.log(items);
      let payload = JSON.parse(items);
      //console.log(payload);
      let list = [];
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i];
        list.push(element.name);
        //console.log(list);
      }

      axios.get("/lifecycledata/ownpremises/" + list, {
          headers: { authorization: `bearer ${token}` },
        })
        .then(function (response) {
          let outcome = response;
          console.log("vastaus", outcome);

          passResult(outcome);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  };
  const inputChanged = (test) => {
    setInput(test.target.value);
    //console.log("tulosta",input);
  };

  function refreshId() {
    //setInput("");
  }
  async function passResult(premise) {
    let results = premise;
    await getPremises(results);
    //console.log("siirtyvät",results);
  }
  async function passOwnResult(premise) {
    let results = premise;
    //await goHom(results);
    //console.log("siirtyvät",results);
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar sx={{ mt: 6, backgroundColor: "#2CB4DF" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 20 }}
            size="medium"
            onClick={getOwnResult}
          >
            get own premises
          </Button>
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            autoFocus
            placeholder="ID or NAME"
            inputProps={{ "aria-label": "search" }}
            onChange={inputChanged}
            value={input}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                click(event);
                event.preventDefault();
              }
            }}
          />
        </Search>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 20 }}
          size="medium"
          onClick={click}
        >
          search
        </Button>
      </Toolbar>
    </Box>
  );
}
