import useAuth from './useAuth';
import { Navigate, useLocation } from 'react-router-dom';

export default function RequireAuth({ children = null }) {
    const { authenticated,isLoading } =  useAuth();
    const location = useLocation();
    if(!isLoading&&!authenticated){
        return <Navigate to='/login' replace />
    }
    return authenticated ? children : <Navigate to={location} replace />
}