import axios from 'axios';
import { createContext, useState, useContext, useEffect } from 'react';
import config from './config';
import { redirect } from "react-router-dom";

const authContext = createContext({});

function useAuthentication() {
    const [authenticated, setAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [iotToken, setIotToken] = useState(null);

    useEffect(() => {
        async function log() {
            let data = sessionStorage.getItem('user');
            if (!data) {
                data = localStorage.getItem('user');
            }
            let tokendata = sessionStorage.getItem('token');
            if (!tokendata) {
                tokendata = localStorage.getItem('token');
            }
            if (data && tokendata) {
                console.log("user loaded from storage");
                setUser(JSON.parse(data));
                setToken(tokendata);
                setAuthenticated(true);
                await getIotToken(tokendata);
                setIsLoading(false);
            } else {
                console.log("no user data found.");
                setIsLoading(false);
            }
        }
        log();
    }, []);

    const getIotToken = async (token) => {
        try {
            let rememberme = false;
            if (sessionStorage.getItem('rememberme') === "true") {
                rememberme = true;
            }
            console.log("getIotToken:");
            let storedToken = sessionStorage.getItem('iottoken');
            if (!storedToken) {
                storedToken = localStorage.getItem('iottoken');
            }
            if (storedToken) {
                setIotToken(storedToken);
            } else {
                let resp = await axios.get(config.flowoneApiUrl + '/lifecycle/iottoken', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                console.log("Response from API:", resp);
                let iottoken = resp.data;
                setIotToken(iottoken);
                sessionStorage.setItem('iottoken', iottoken);
                if (rememberme) {
                    localStorage.setItem('iottoken', iottoken);
                }
            }
        } catch (err) {
            console.error("Error getting iot token:", err);
        }
    }

    return {
        user,
        authenticated,
        token,
        iotToken,
        isLoading,
        login: async (token, user) => {
            console.log("useAuth login:");
            try {
                let rememberme = false;
                if (sessionStorage.getItem('rememberme') === "true") {
                    rememberme = true;
                }
                setUser(user);
                setToken(token);
                setAuthenticated(true);
                await getIotToken(token, rememberme);
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', token);
                //if (sessionStorage.getItem('rememberme')==="true") {
                if (rememberme) {
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', token);
                }
            } catch (err) {
                console.error("Error setting user data:", err);
            }
        },
        logout: async () => {
            console.log("Logout called:");
            setUser(null);
            setAuthenticated(false);
            sessionStorage.removeItem('user');
            localStorage.removeItem('user');
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            sessionStorage.removeItem('iottoken');
            localStorage.removeItem('iottoken');
        },
    };
};

export function AuthProvider({ children }) {
    const auth = useAuthentication();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default function useAuth() {
    return useContext(authContext);
}
