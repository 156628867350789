import {
    Box,
    LinearProgress,
    Typography
} from '@mui/material'

export default function TransferProgressBar({progress,transfered,transferRate}) {
    console.log("progress:",progress);
    return (
        <Box sx={{ paddingTop: 5 }}>
            <Box>Transfering...</Box>
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        progress,
                    )}%`}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'right', }} justifyContent="flex-end"><Typography sx={{ fontSize: 10 }}> transfered:{transfered} speed:{transferRate}</Typography></Box>
        </Box>
    )
}